/**
 * @flow
 * @relayHash d4ea1b5be3c58a7b17f3f2002a7fc8e1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExportListInput = {
  query: string,
  type: string,
  clientMutationId?: ?string,
};
export type ExportListExportListMutationVariables = {|
  input: ExportListInput
|};
export type ExportListExportListMutationResponse = {|
  +exportList: ?{|
    +success: ?boolean
  |}
|};
export type ExportListExportListMutation = {|
  variables: ExportListExportListMutationVariables,
  response: ExportListExportListMutationResponse,
|};
*/


/*
mutation ExportListExportListMutation(
  $input: ExportListInput!
) {
  exportList(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ExportListInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "exportList",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ExportListInput!"
      }
    ],
    "concreteType": "ExportListPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ExportListExportListMutation",
  "id": null,
  "text": "mutation ExportListExportListMutation(\n  $input: ExportListInput!\n) {\n  exportList(input: $input) {\n    success\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ExportListExportListMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ExportListExportListMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ec7803b478a3db35e47469934caf9dc6';
module.exports = node;
