/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ArticleForm_article$ref: FragmentReference;
export type ArticleForm_article = {|
  +title?: ?string,
  +summary?: ?string,
  +url?: ?string,
  +language?: ?string,
  +tags?: ?$ReadOnlyArray<?string>,
  +rating?: ?string,
  +report_status?: ?string,
  +updated_at?: ?string,
  +user?: ?{|
    +name: ?string
  |},
  +claim_description?: ?{|
    +description: ?string,
    +context: ?string,
    +project_media: ?{|
      +dbid: ?number,
      +title: ?string,
      +type: ?string,
      +full_url: ?string,
      +last_status_obj: ?{|
        +locked: ?boolean
      |},
    |},
  |},
  +description?: ?string,
  +$refType: ArticleForm_article$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tags",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updated_at",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ArticleForm_article",
  "type": "Node",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "InlineFragment",
      "type": "FactCheck",
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "summary",
          "args": null,
          "storageKey": null
        },
        v1,
        v2,
        v3,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "rating",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "report_status",
          "args": null,
          "storageKey": null
        },
        v4,
        v5,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "claim_description",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimDescription",
          "plural": false,
          "selections": [
            v6,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "context",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "project_media",
              "storageKey": null,
              "args": null,
              "concreteType": "ProjectMedia",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "dbid",
                  "args": null,
                  "storageKey": null
                },
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "full_url",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "last_status_obj",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Dynamic",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "locked",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "InlineFragment",
      "type": "Explainer",
      "selections": [
        v0,
        v6,
        v1,
        v2,
        v3,
        v4,
        v5
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c79ec348992ba73b56bc9b0f0cde7e0';
module.exports = node;
