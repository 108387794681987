/**
 * @flow
 * @relayHash c6238fabc26b6e534d0a8393ff1b6793
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CreateMediaButton_team$ref = any;
export type CreateMediaQueryVariables = {||};
export type CreateMediaQueryResponse = {|
  +me: ?{|
    +token: ?string
  |},
  +team: ?{|
    +slug: string,
    +$fragmentRefs: CreateMediaButton_team$ref,
  |},
|};
export type CreateMediaQuery = {|
  variables: CreateMediaQueryVariables,
  response: CreateMediaQueryResponse,
|};
*/


/*
query CreateMediaQuery {
  me {
    token
    id
  }
  team {
    slug
    ...CreateMediaButton_team
    id
  }
}

fragment CreateMediaButton_team on Team {
  dbid
  slug
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "token",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CreateMediaQuery",
  "id": null,
  "text": "query CreateMediaQuery {\n  me {\n    token\n    id\n  }\n  team {\n    slug\n    ...CreateMediaButton_team\n    id\n  }\n}\n\nfragment CreateMediaButton_team on Team {\n  dbid\n  slug\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateMediaQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v0
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "FragmentSpread",
            "name": "CreateMediaButton_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateMediaQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v0,
          v2
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": null,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dbid",
            "args": null,
            "storageKey": null
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '981921c36df20355e4f3a6d84b10fa6b';
module.exports = node;
