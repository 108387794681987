/**
 * @flow
 * @relayHash a5d638bc80814850502912e2113e7d87
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateFactCheckInput = {
  url?: ?string,
  language?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  rating?: ?string,
  title: string,
  summary: string,
  claim_description_id: number,
  clientMutationId?: ?string,
};
export type ClaimFactCheckFormCreateFactCheckMutationVariables = {|
  input: CreateFactCheckInput
|};
export type ClaimFactCheckFormCreateFactCheckMutationResponse = {|
  +createFactCheck: ?{|
    +team: ?{|
      +factChecksCount: ?number,
      +totalArticlesCount: ?number,
    |},
    +claim_description: ?{|
      +description: ?string,
      +context: ?string,
      +project_media: ?{|
        +title: ?string,
        +title_field: ?string,
        +custom_title: ?string,
        +status: ?string,
        +last_status: ?string,
        +last_status_obj: ?{|
          +id: string,
          +locked: ?boolean,
        |},
      |},
    |},
    +fact_checkEdge: ?{|
      +__typename: string,
      +cursor: string,
      +node: ?{|
        +id: string,
        +title: ?string,
        +summary: ?string,
        +url: ?string,
        +language: ?string,
        +rating: ?string,
        +tags: ?$ReadOnlyArray<?string>,
        +updated_at: ?string,
        +user: ?{|
          +name: ?string
        |},
      |},
    |},
  |}
|};
export type ClaimFactCheckFormCreateFactCheckMutation = {|
  variables: ClaimFactCheckFormCreateFactCheckMutationVariables,
  response: ClaimFactCheckFormCreateFactCheckMutationResponse,
|};
*/


/*
mutation ClaimFactCheckFormCreateFactCheckMutation(
  $input: CreateFactCheckInput!
) {
  createFactCheck(input: $input) {
    team {
      factChecksCount: articles_count(article_type: "fact-check")
      totalArticlesCount: articles_count
      id
    }
    claim_description {
      description
      context
      project_media {
        title
        title_field
        custom_title
        status
        last_status
        last_status_obj {
          id
          locked
        }
        id
      }
      id
    }
    fact_checkEdge {
      __typename
      cursor
      node {
        id
        title
        summary
        url
        language
        rating
        tags
        updated_at
        user {
          name
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateFactCheckInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateFactCheckInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "factChecksCount",
  "name": "articles_count",
  "args": [
    {
      "kind": "Literal",
      "name": "article_type",
      "value": "fact-check",
      "type": "String"
    }
  ],
  "storageKey": "articles_count(article_type:\"fact-check\")"
},
v3 = {
  "kind": "ScalarField",
  "alias": "totalArticlesCount",
  "name": "articles_count",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "context",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title_field",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "custom_title",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "last_status",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "last_status_obj",
  "storageKey": null,
  "args": null,
  "concreteType": "Dynamic",
  "plural": false,
  "selections": [
    v11,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "locked",
      "args": null,
      "storageKey": null
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tags",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updated_at",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ClaimFactCheckFormCreateFactCheckMutation",
  "id": null,
  "text": "mutation ClaimFactCheckFormCreateFactCheckMutation(\n  $input: CreateFactCheckInput!\n) {\n  createFactCheck(input: $input) {\n    team {\n      factChecksCount: articles_count(article_type: \"fact-check\")\n      totalArticlesCount: articles_count\n      id\n    }\n    claim_description {\n      description\n      context\n      project_media {\n        title\n        title_field\n        custom_title\n        status\n        last_status\n        last_status_obj {\n          id\n          locked\n        }\n        id\n      }\n      id\n    }\n    fact_checkEdge {\n      __typename\n      cursor\n      node {\n        id\n        title\n        summary\n        url\n        language\n        rating\n        tags\n        updated_at\n        user {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ClaimFactCheckFormCreateFactCheckMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createFactCheck",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateFactCheckPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "project_media",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v6,
                  v7,
                  v8,
                  v9,
                  v10,
                  v12
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fact_checkEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "FactCheckEdge",
            "plural": false,
            "selections": [
              v13,
              v14,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "FactCheck",
                "plural": false,
                "selections": [
                  v11,
                  v6,
                  v15,
                  v16,
                  v17,
                  v18,
                  v19,
                  v20,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v21
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ClaimFactCheckFormCreateFactCheckMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createFactCheck",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateFactCheckPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              v3,
              v11
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "project_media",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v6,
                  v7,
                  v8,
                  v9,
                  v10,
                  v12,
                  v11
                ]
              },
              v11
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fact_checkEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "FactCheckEdge",
            "plural": false,
            "selections": [
              v13,
              v14,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "FactCheck",
                "plural": false,
                "selections": [
                  v11,
                  v6,
                  v15,
                  v16,
                  v17,
                  v18,
                  v19,
                  v20,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v21,
                      v11
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c03e78e14d69ad22d2b19307bca8730a';
module.exports = node;
