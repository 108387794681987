/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ClaimFactCheckForm_article$ref = any;
type ClaimFactCheckForm_team$ref = any;
type ExplainerForm_article$ref = any;
type ExplainerForm_team$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaArticlesDisplay_projectMedia$ref: FragmentReference;
export type MediaArticlesDisplay_projectMedia = {|
  +type: ?string,
  +fact_check_published_on: ?number,
  +team: ?{|
    +verification_statuses: ?any,
    +$fragmentRefs: ClaimFactCheckForm_team$ref & ExplainerForm_team$ref,
  |},
  +fact_check: ?{|
    +id: string,
    +title: ?string,
    +summary: ?string,
    +language: ?string,
    +updated_at: ?string,
    +url: ?string,
    +report_status: ?string,
    +rating: ?string,
    +claim_description: ?{|
      +id: string,
      +description: ?string,
      +context: ?string,
    |},
    +nodeType: string,
    +$fragmentRefs: ClaimFactCheckForm_article$ref,
  |},
  +explainer_items: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +explainer: {|
          +language: ?string,
          +title: ?string,
          +description: ?string,
          +url: ?string,
          +updated_at: ?string,
          +nodeType: string,
          +$fragmentRefs: ExplainerForm_article$ref,
        |},
      |}
    |}>
  |},
  +$refType: MediaArticlesDisplay_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updated_at",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": "nodeType",
  "name": "__typename",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MediaArticlesDisplay_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fact_check_published_on",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "verification_statuses",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ClaimFactCheckForm_team",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ExplainerForm_team",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fact_check",
      "storageKey": null,
      "args": null,
      "concreteType": "FactCheck",
      "plural": false,
      "selections": [
        v0,
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "summary",
          "args": null,
          "storageKey": null
        },
        v2,
        v3,
        v4,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "report_status",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "rating",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "claim_description",
          "storageKey": null,
          "args": null,
          "concreteType": "ClaimDescription",
          "plural": false,
          "selections": [
            v0,
            v5,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "context",
              "args": null,
              "storageKey": null
            }
          ]
        },
        v6,
        {
          "kind": "FragmentSpread",
          "name": "ClaimFactCheckForm_article",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "explainer_items",
      "storageKey": "explainer_items(first:100)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100,
          "type": "Int"
        }
      ],
      "concreteType": "ExplainerItemConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ExplainerItemEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ExplainerItem",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "explainer",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Explainer",
                  "plural": false,
                  "selections": [
                    v2,
                    v1,
                    v5,
                    v4,
                    v3,
                    v6,
                    {
                      "kind": "FragmentSpread",
                      "name": "ExplainerForm_article",
                      "args": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e29daefd90617dc2226c4c2156250ccf';
module.exports = node;
