/**
 * @flow
 * @relayHash 85176c4b4e8d049c6a3cdc759030b1ca
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProjectMediaInput = {
  media_id?: ?number,
  related_to_id?: ?number,
  url?: ?string,
  quote?: ?string,
  quote_attributions?: ?string,
  project_id?: ?number,
  team_id?: ?number,
  channel?: ?any,
  media_type?: ?string,
  set_annotation?: ?string,
  set_claim_description?: ?string,
  set_fact_check?: ?any,
  set_tasks_responses?: ?any,
  set_tags?: ?any,
  set_title?: ?string,
  set_status?: ?string,
  set_original_claim?: ?string,
  clientMutationId?: ?string,
};
export type CreateMediaButtonCreateMutationVariables = {|
  input: CreateProjectMediaInput
|};
export type CreateMediaButtonCreateMutationResponse = {|
  +createProjectMedia: ?{|
    +project_media: ?{|
      +dbid: ?number
    |}
  |}
|};
export type CreateMediaButtonCreateMutation = {|
  variables: CreateMediaButtonCreateMutationVariables,
  response: CreateMediaButtonCreateMutationResponse,
|};
*/


/*
mutation CreateMediaButtonCreateMutation(
  $input: CreateProjectMediaInput!
) {
  createProjectMedia(input: $input) {
    project_media {
      dbid
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProjectMediaInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateProjectMediaInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateMediaButtonCreateMutation",
  "id": null,
  "text": "mutation CreateMediaButtonCreateMutation(\n  $input: CreateProjectMediaInput!\n) {\n  createProjectMedia(input: $input) {\n    project_media {\n      dbid\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateMediaButtonCreateMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProjectMedia",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProjectMediaPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateMediaButtonCreateMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProjectMedia",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProjectMediaPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '935c8b927af329e9b212e95491da8383';
module.exports = node;
