/**
 * @flow
 * @relayHash fc49f00a4b1df1484ab6cdcd8318c41e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ArticlesQueryVariables = {|
  slug: string,
  type: string,
  pageSize?: ?number,
  sort?: ?string,
  sortType?: ?string,
  offset?: ?number,
  users?: ?$ReadOnlyArray<?number>,
  updated_at?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  language?: ?$ReadOnlyArray<?string>,
  published_by?: ?$ReadOnlyArray<?number>,
  report_status?: ?$ReadOnlyArray<?string>,
  verification_status?: ?$ReadOnlyArray<?string>,
  imported?: ?boolean,
  text?: ?string,
|};
export type ArticlesQueryResponse = {|
  +team: ?{|
    +name: string,
    +slug: string,
    +totalArticlesCount: ?number,
    +verification_statuses: ?any,
    +tag_texts: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +text: ?string
        |}
      |}>
    |},
    +articles_count: ?number,
    +articles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id?: string,
          +dbid?: ?number,
          +title?: ?string,
          +description?: ?string,
          +url?: ?string,
          +language?: ?string,
          +updated_at?: ?string,
          +tags?: ?$ReadOnlyArray<?string>,
          +summary?: ?string,
          +rating?: ?string,
          +report_status?: ?string,
          +claim_description?: ?{|
            +id: string,
            +context: ?string,
            +description: ?string,
            +project_media: ?{|
              +dbid: ?number,
              +fact_check_published_on: ?number,
            |},
          |},
        |}
      |}>
    |},
  |}
|};
export type ArticlesQuery = {|
  variables: ArticlesQueryVariables,
  response: ArticlesQueryResponse,
|};
*/


/*
query ArticlesQuery(
  $slug: String!
  $type: String!
  $pageSize: Int
  $sort: String
  $sortType: String
  $offset: Int
  $users: [Int]
  $updated_at: String
  $tags: [String]
  $language: [String]
  $published_by: [Int]
  $report_status: [String]
  $verification_status: [String]
  $imported: Boolean
  $text: String
) {
  team(slug: $slug) {
    name
    slug
    totalArticlesCount: articles_count
    verification_statuses
    tag_texts(first: 100) {
      edges {
        node {
          text
          id
        }
      }
    }
    articles_count(article_type: $type, user_ids: $users, tags: $tags, updated_at: $updated_at, language: $language, text: $text, publisher_ids: $published_by, report_status: $report_status, rating: $verification_status, imported: $imported)
    articles(first: $pageSize, article_type: $type, offset: $offset, sort: $sort, sort_type: $sortType, user_ids: $users, tags: $tags, updated_at: $updated_at, language: $language, publisher_ids: $published_by, report_status: $report_status, rating: $verification_status, imported: $imported, text: $text) {
      edges {
        node {
          __typename
          ... on Explainer {
            id
            dbid
            title
            description
            url
            language
            updated_at
            tags
          }
          ... on FactCheck {
            id
            dbid
            title
            summary
            url
            language
            updated_at
            rating
            report_status
            tags
            claim_description {
              id
              context
              description
              project_media {
                dbid
                fact_check_published_on
                id
              }
            }
          }
          ... on Node {
            id
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortType",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "users",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "updated_at",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tags",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "language",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "published_by",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "report_status",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "verification_status",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "imported",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "text",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": "totalArticlesCount",
  "name": "articles_count",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "verification_statuses",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100,
    "type": "Int"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "text",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "Variable",
  "name": "imported",
  "variableName": "imported",
  "type": "Boolean"
},
v9 = {
  "kind": "Variable",
  "name": "language",
  "variableName": "language",
  "type": "[String]"
},
v10 = {
  "kind": "Variable",
  "name": "publisher_ids",
  "variableName": "published_by",
  "type": "[Int]"
},
v11 = {
  "kind": "Variable",
  "name": "rating",
  "variableName": "verification_status",
  "type": "[String]"
},
v12 = {
  "kind": "Variable",
  "name": "report_status",
  "variableName": "report_status",
  "type": "[String]"
},
v13 = {
  "kind": "Variable",
  "name": "tags",
  "variableName": "tags",
  "type": "[String]"
},
v14 = {
  "kind": "Variable",
  "name": "text",
  "variableName": "text",
  "type": "String"
},
v15 = {
  "kind": "Variable",
  "name": "updated_at",
  "variableName": "updated_at",
  "type": "String"
},
v16 = {
  "kind": "Variable",
  "name": "user_ids",
  "variableName": "users",
  "type": "[Int]"
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "articles_count",
  "args": [
    {
      "kind": "Variable",
      "name": "article_type",
      "variableName": "type",
      "type": "String"
    },
    v8,
    v9,
    v10,
    v11,
    v12,
    v13,
    v14,
    v15,
    v16
  ],
  "storageKey": null
},
v18 = [
  {
    "kind": "Variable",
    "name": "article_type",
    "variableName": "type",
    "type": "String!"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize",
    "type": "Int"
  },
  v8,
  v9,
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset",
    "type": "Int"
  },
  v10,
  v11,
  v12,
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "sort_type",
    "variableName": "sortType",
    "type": "String"
  },
  v13,
  v14,
  v15,
  v16
],
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updated_at",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tags",
  "args": null,
  "storageKey": null
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v28 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating",
  "args": null,
  "storageKey": null
},
v29 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "report_status",
  "args": null,
  "storageKey": null
},
v30 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "context",
  "args": null,
  "storageKey": null
},
v31 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fact_check_published_on",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ArticlesQuery",
  "id": null,
  "text": "query ArticlesQuery(\n  $slug: String!\n  $type: String!\n  $pageSize: Int\n  $sort: String\n  $sortType: String\n  $offset: Int\n  $users: [Int]\n  $updated_at: String\n  $tags: [String]\n  $language: [String]\n  $published_by: [Int]\n  $report_status: [String]\n  $verification_status: [String]\n  $imported: Boolean\n  $text: String\n) {\n  team(slug: $slug) {\n    name\n    slug\n    totalArticlesCount: articles_count\n    verification_statuses\n    tag_texts(first: 100) {\n      edges {\n        node {\n          text\n          id\n        }\n      }\n    }\n    articles_count(article_type: $type, user_ids: $users, tags: $tags, updated_at: $updated_at, language: $language, text: $text, publisher_ids: $published_by, report_status: $report_status, rating: $verification_status, imported: $imported)\n    articles(first: $pageSize, article_type: $type, offset: $offset, sort: $sort, sort_type: $sortType, user_ids: $users, tags: $tags, updated_at: $updated_at, language: $language, publisher_ids: $published_by, report_status: $report_status, rating: $verification_status, imported: $imported, text: $text) {\n      edges {\n        node {\n          __typename\n          ... on Explainer {\n            id\n            dbid\n            title\n            description\n            url\n            language\n            updated_at\n            tags\n          }\n          ... on FactCheck {\n            id\n            dbid\n            title\n            summary\n            url\n            language\n            updated_at\n            rating\n            report_status\n            tags\n            claim_description {\n              id\n              context\n              description\n              project_media {\n                dbid\n                fact_check_published_on\n                id\n              }\n            }\n          }\n          ... on Node {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ArticlesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tag_texts",
            "storageKey": "tag_texts(first:100)",
            "args": v6,
            "concreteType": "TagTextConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagTextEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagText",
                    "plural": false,
                    "selections": [
                      v7
                    ]
                  }
                ]
              }
            ]
          },
          v17,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "articles",
            "storageKey": null,
            "args": v18,
            "concreteType": "ArticleUnionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ArticleUnionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "type": "Explainer",
                        "selections": [
                          v19,
                          v20,
                          v21,
                          v22,
                          v23,
                          v24,
                          v25,
                          v26
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "type": "FactCheck",
                        "selections": [
                          v19,
                          v20,
                          v21,
                          v27,
                          v23,
                          v24,
                          v25,
                          v28,
                          v29,
                          v26,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "claim_description",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ClaimDescription",
                            "plural": false,
                            "selections": [
                              v19,
                              v30,
                              v22,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "project_media",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ProjectMedia",
                                "plural": false,
                                "selections": [
                                  v20,
                                  v31
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ArticlesQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tag_texts",
            "storageKey": "tag_texts(first:100)",
            "args": v6,
            "concreteType": "TagTextConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagTextEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagText",
                    "plural": false,
                    "selections": [
                      v7,
                      v19
                    ]
                  }
                ]
              }
            ]
          },
          v17,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "articles",
            "storageKey": null,
            "args": v18,
            "concreteType": "ArticleUnionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ArticleUnionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      },
                      v19,
                      {
                        "kind": "InlineFragment",
                        "type": "Explainer",
                        "selections": [
                          v20,
                          v21,
                          v22,
                          v23,
                          v24,
                          v25,
                          v26
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "type": "FactCheck",
                        "selections": [
                          v20,
                          v21,
                          v27,
                          v23,
                          v24,
                          v25,
                          v28,
                          v29,
                          v26,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "claim_description",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ClaimDescription",
                            "plural": false,
                            "selections": [
                              v19,
                              v30,
                              v22,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "project_media",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ProjectMedia",
                                "plural": false,
                                "selections": [
                                  v20,
                                  v31,
                                  v19
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v19
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '81415937c725d22f85d73678c2fda0e1';
module.exports = node;
